const customer = [
  // {
  //   id: 1,
  //   name: "Dr. Drain Rooter and Hydro Jett",
  //   address: "212 W Linden Ave unit D, Burbank, CA 91502",
  //   email: "drdrainrooterandhydrojett@gmail.com",
  //   phone: "(661) 505-3277",
  // },
  // {
  //   id: 2,
  //   name: "A-Craft Plumbing Inc.",
  //   address: "916 W Burbank Blvd Unit C. #175, Burbank, CA 91506",
  //   email: "acraftplumbinginc@gmail.com",
  //   phone: "(818) 721-7543",
  // },
  // {
  //   id: 3,
  //   name: "Go With The Flow Plumbing",
  //   address: "Burbank, CA",
  //   email: "gowiththeflowplumbing@gmail.com",
  //   phone: "(818) 741-4020",
  // },
  // {
  //   id: 4,
  //   name: "The Drain Defender",
  //   address: "7049 1/2 Vineland Ave, North Hollywood, CA 91605",
  //   email: "thedraindefender@gmail.com",
  //   phone: "(818) 845-4183",
  // },
  // {
  //   id: 5,
  //   name: "Prestige Plumbing Services, Inc.",
  //   address: "221 N 3rd St #604, Burbank, CA 91502",
  //   email: "prestigeplumbing@gmail.com",
  //   phone: "(818) 688-1595",
  // },
  {
    id: 6,
    name: "Fluid Pro Plumbing",
    address: "712 E Elmwood Ave., Burbank, CA 91502",
    email: "prestigeplumbing@gmail.com",
    phone: "(323) 203-5771",
  },
];
export default customer;
